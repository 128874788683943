<template>
    <component v-bind:is="layout"></component>
</template>

<script>
import AppLayout from './layouts/AppLayout.vue';
import LoginLayout from './layouts/LoginLayout.vue';

export default {
  setup() {
    
  },
  computed: {
    layout () {
      return this.$store.getters.layout
    }
  },
  components: {
    'app-layout': AppLayout,
    'login-layout': LoginLayout
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
