import { createStore } from 'vuex'

function initialState() {
  return {
    token: null,
    user: null,
    pageTitle:'Dashboard'
  }
}

export default createStore({
  state: {
    layout: 'login-layout',
    initialState
  },
  getters: {
    layout (state) {
      return state.layout
    },
    user: (state) => {
      return state.user
    },
    token: (state) => {
      return state.token
    },
    pageTitle: (state) => {
      return state.pageTitle
    }
  },
  mutations: {
    SET_LAYOUT (state, payload) {
      state.layout = payload
    },
    user(state, user) {
      state.user = user;
    },
    token(state, token) {
      state.token = token;
    },
    pageTitle(state, pageTitle) {
      state.pageTitle = pageTitle;
    },
    reset (state) {
      // acquire initial state
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  actions: {
    user(context, user) {
      context.commit('user', user)
    },
    token(context, token) {
      context.commit('token', token)
    },
    pageTitle(context, pageTitle) {
      context.commit('pageTitle', pageTitle)
    }
  },
  modules: {
  }
})
