import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth/LoginView')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Auth/LogOut')
  },
  {
    path: '/uc',
    name: "UNDERCONSTRUCTION",
    component: () => import("../views/App/Home/UnderConstruction"),
  },
  {
    path: "/accessdenied",
    name: "AccessDenied",
    component: () => import("../views/App/Home/AccessDenied"),
  },
  {
    path: "/test",
    name: "AdminTest",
    component: () => import("../views/App/Admin/TestPage"),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/App/Home/DashBoard')
  },
  {
    path: "/itemmaster",
    name: "ItemMaster",
    component: () => import("../views/App/Inventory/ItemMaster.vue")
  },
  {
    path: "/itemcategories",
    name: "Item Categories",
    component: () => import("../views/App/Inventory/ItemsCategories.vue")
  },
  {
    path: "/productionfoundryjobcards",
    name: "Foundry Job Card Overview",
    component: () => import("../views/App/Production/Foundry/JobCards")
  },
  {
    path: "/productionfoundryjobcardnew",
    name: "Foundry Job Card New",
    component: () => import("../views/App/Production/Foundry/JobCard")
  },
  {
    path: "/productionfoundryjobcard/:id",
    name: "Foundry Job Card",
    component: () => import("../views/App/Production/Foundry/JobCard")
  },
  {
    path: "/productionfoundryjobcardsummary",
    name: "Foundry Job Card Summary",
    component: () => import("../views/App/Production/Foundry/SummaryReport")
  },
  {
    path: "/productionfoundryjobcardsummaryitems",
    name: "Foundry Job Card Item Summary",
    component: () => import("../views/App/Production/Foundry/SummaryItemReport")
  },
  {
    path: "/productionfurnacerecordnew",
    name: "Melting Record New",
    component: () => import("../views/App/Production/Foundry/MeltingRecord")
  },
  {
    path: "/productionfurnacerecord/:id",
    name: "Melting Record",
    component: () => import("../views/App/Production/Foundry/MeltingRecord")
  },
  {
    path: "/productionfurnacerecords",
    name: "Melting Records",
    component: () => import("../views/App/Production/Foundry/MeltingRecords")
  },
  {
    path: "/usersview",
    name: "Users",
    component: () => import("../views/App/Users/UsersView.vue")
  },
  {
    path: '/usersEdit/:id',
    name: 'Users Edit',
    component: () => import( '../views/App/Users/UsersEdit.vue')
  },
  {
    path: '/usersLogins',
    name: 'Users Logins',
    component: () => import( '../views/App/Users/UsersLogins.vue')
  },
  {
    path: '/usersMyProfile',
    name: 'My Profile',
    component: () => import( '../views/App/Users/UsersMyProfile.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


import store from "../store";

router.beforeEach((to, from, next) => {
  document.title = "UDAYA | " + to.name;
  if (to.name !== "Login" && !store.state.user) {
    next({ name: "Login" });
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router
