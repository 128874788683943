import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css'
import "@/assets/css/styles.css"
import "@/assets/css/tree.css"

axios.defaults.baseURL = "https://api.udayaindustries.lk/api/";

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log("RESPONSE:" + error.response.data)
        switch (error.response.status) {
            case 0:
                break;
            case 400:
                this.$router.push("/login");
                break;
            case 401:
                // Handle Unauthorized calls here
                // Display an alert
                store.state.token = null;
                store.state.user = null;
                if (error.response.data.page != "login") {
                    router.push("/login");
                }
                break;
            case 500:
                // Handle 500 here
                // redirect
                this.$router.push("/login");
                break;
            case 900:
                // access denied 
                // for users
                this.$router.push("/accessdenied");
                break;
            // and so on..
        }
        return Promise.reject(error);
    }
);

import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import Notifications from '@kyvg/vue3-notification'
import "@/assets/css/notification.css"

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import VueSweetalert2 from 'vue-sweetalert2';
//import 'sweetalert2/dist/sweetalert2.min.css';
//import "@/assets/css/sweetAlertCustom.css"

import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

const app =createApp(App);
app.config.globalProperties.$HostUrl="https://api.udayaindustries.lk/";



app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(LoadingPlugin);
app.use(Notifications);
app.use(VueSweetalert2);
app.use(VCalendar, {});
app.component('v-select', vSelect);

app.mount('#app');
