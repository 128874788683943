<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <button @click="togglesidebar" class="btn btn-link">
        <i class="bi bi-list"></i>
      </button>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="text-center" style="width: inherit; font-weight: bold">
        {{ this.$store.state.pageTitle }}
      </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
        <ul class="nav navbar-nav navbar-right">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ user.namefirst }}&nbsp;{{ user.namelast }}
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="userDropdown"
            >
              <li>
                <a class="dropdown-item" href="#" @click.prevent="myProfile"
                  ><i class="bi bi-person"></i> &nbsp;Profile</a
                >
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item" href="#" @click.prevent="logout"
                  ><i class="bi bi-power"></i> &nbsp; Logout</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
  <script>
import store from "../../store";
import axios from "axios";
export default {
  name: "TopNav",
  data() {
    return {
      menuOpen: true,
      user: store.state.user,
      msg:null
    };
  },
  setup() {},
  methods: {
    async togglesidebar() {
      if (this.menuOpen) {
        document.getElementById("sidenav").style.width = "0";
        document.getElementById("main").style.marginLeft = "0";
        this.menuOpen = false;
      } else {
        document.getElementById("sidenav").style.width = "270px";
        document.getElementById("main").style.marginLeft = "270px";
        this.menuOpen = true;
      }
    },
    async logout() {
      try {
        const response = await axios.post("/logout");
        localStorage.removeItem("token");
        this.$router.push("/login");
        this.$store.commit("reset");
        this.msg=response.data.text;
      } catch (err) {
        console.log("error");
      }
    },
    async myProfile() {
      this.$router.push("/usersMyProfile");
    },
  },
};
</script>
  
  <style>
</style>