<template>
  <div id="sidenav" class="sidenav">
    <div id="imgNav" class="imgNav">
      <img class="mImg" src="@/assets/img/nav/sidenav.jpg" />
    </div>

    <div id="mnu" class="mnu" v-if="isDivisionProduction">
      <nav class="tree-nav">
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">
            <router-link :to="'/'" class="tree-nav__item-title"
              >Home
            </router-link>
          </summary>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Cust. Orders</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Orders</summary>
            <div class="tree-nav__item">
              <router-link :to="'/uc'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Order Book
              </router-link>
              <router-link :to="'/uc'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Customers
              </router-link>
            </div>
          </details>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Inventory</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Products & Items</summary>
            <div class="tree-nav__item">
              <router-link :to="'/itemmaster'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Master List
              </router-link>
              <router-link :to="'/itemcategories'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Categories
              </router-link>
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Stock</summary>
            <div class="tree-nav__item">
              <router-link :to="'/uc'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Balance
              </router-link>
              <router-link :to="'/uc'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Item History
              </router-link>
              <router-link :to="'/uc'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Locations
              </router-link>
            </div>
          </details>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Production</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Foundry</summary>
            <details class="tree-nav__item is-expandable">
              <summary class="tree-nav__item-title">Job Cards</summary>
              <div class="tree-nav__item">
                <router-link
                  :to="'/productionfoundryjobcards'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> History
                </router-link>
                <router-link
                  :to="'/productionfoundryjobcardnew'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> New
                </router-link>
              </div>
            </details>
            <details class="tree-nav__item is-expandable">
              <summary class="tree-nav__item-title">Melting Records</summary>
              <div class="tree-nav__item">
                <router-link
                  :to="'/uc'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> History
                </router-link>
                <router-link
                  :to="'/uc'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> New
                </router-link>
              </div>
            </details>
            <details class="tree-nav__item is-expandable">
              <summary class="tree-nav__item-title">Reports</summary>
              <div class="tree-nav__item">
                <router-link
                  :to="'/productionfoundryjobcardsummary'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Summary
                </router-link>
              </div>
              <div class="tree-nav__item">
                <router-link
                  :to="'/productionfoundryjobcardsummaryitems'"
                  class="tree-nav__item-title"
                  ><i class="bi bi-dot"></i> Item Summary
                </router-link>
              </div>
            </details>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Assembly & Manuf.</summary>
            <div class="tree-nav__item">
              <router-link :to="'/uc'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Job Card
              </router-link>
            </div>
          </details>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Supply Chain</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Purchasing</summary>
            <div class="tree-nav__item">
              <router-link :to="'/uc'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Purchase Request
              </router-link>
              <router-link :to="'/uc'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Purchase Order
              </router-link>
            </div>
          </details>
        </details>
        <details class="tree-nav__item is-expandable" v-if="isSystem">
          <summary class="tree-nav__item-title">System</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Users</summary>
            <div class="tree-nav__item">
              <router-link :to="'/usersview'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Users</router-link
              >
              <router-link :to="'/usersLogins'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Users Login History</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Admin</summary>
            <div class="tree-nav__item">
              <router-link :to="'/test'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Test Page</router-link
              >
            </div>
          </details>
        </details>
      </nav>
    </div>
  </div>
</template>
<script>
import store from "../../store";
export default {
  setup() {},

  data() {
    return {
      isAdmin: false,
      isSystem: false,
      role: null,
      isDivisionProduction: false,
      isDivistionSales: false,
    };
  },
  mounted() {
    this.checkSystem();
  },
  methods: {
    async checkSystem() {
      this.role = store.state.user.role;
      if (this.role == "System") {
        this.isSystem = true;
      }
      if (store.state.user.division == "production") {
        this.isDivisionProduction = true;
      }
      if (store.state.user.division == "sales") {
        this.isDivistionSales = true;
      }
    },
  },
};
</script>
<style scoped>
.sidenav {
  transition: 00.5s;
  width: 270px;
  left: 0;
  float: left;
  flex-wrap: wrap;
  height: 100vh;
  overflow: scroll;
  background-color: rgb(37, 37, 37);
  font-size: 0.9em;
}

.mImg {
  width: 100%;
}

.imgNav {
  height: auto;
}

.mnu {
  height: inherit;
}

.accordion-item:last-of-type,
.accordion-button.collapsed,
.accordion-item:first-of-type {
  border-radius: 0;
}
</style>
